<template>
  <div class="container">
    <div class="uploadFile" >
      <input type="file" @change="handleFileUpload" />
      <select v-model="selectedType">
        <option disable value="">--请选择模板--</option>
        <option v-for="(option, index) in options" :key="index" :value="index + 1">
          {{ option }}
        </option>
      </select>
      <button @click="submitFile" class="btn">上传并下载简历</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedType: null,
      file: null,
      options: [
        '类别一初级模板',
        '类别一中级模板',
        '类别一高级模板',
        '类别一资深模板',
        '类别二初级模板',
        '类别二中级模板',
        '类别二高级模板',
        '类别二资深模板'
      ]
    };
  },
  methods: {
    handleFileUpload(event) {
        this.selectedFile = event.target.files[0];
      },
      submitFile() {
        if (!this.selectedFile) {
          alert("请选择文件！");
          return; // 不发送请求
        }
        if (!this.selectedType) {
          alert("请选择模板！");
          return; // 不发送请求
        }
        const formData = new FormData();
        formData.append('file', this.selectedFile);
        formData.append('type', this.selectedType);

        this.$axios.post('http://1.94.103.30:8080/unityDesk/parseResumeTest', formData, {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(response => {
          // 打断点
          console.log(response);
          if (response.data.code === 500) {
            alert(response.data.msg); // 显示错误消息
          } else {
            // 处理其他成功响应
            // 处理响应，例如下载文件
            // const unit8Array = new Uint8Array(response.data);
            const url = window.URL.createObjectURL(response.data); // 创建一个URL对象
            const link = document.createElement('a'); // 创建一个a标签
            link.href = url; // 设置a标签的href属性为URL对象
            link.setAttribute('download', this.selectedFile.name); // 或从响应中获取文件名
            document.body.appendChild(link); // 将a标签添加到DOM中
            link.click();
          }
          
        }).catch(error => {
          console.error('Error:', error);
        });
      },
  }
};
</script>

<style>
.container {
  display: flex;
  justify-content: center;
  align-items: center;

}
</style>
